import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
        style={{marginLeft: '30px', marginRight: '30px', marginTop:'20px', marginBottom:'30px', display:'inline-table'}}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={12} md={12} sm={24} xs={24}>
              <div style={{ textAlign: 'center', marginBottom: '25px', marginTop: '20px'}} >
                <Para style={{width: '100%'}}>
                  {t(`Do you have any question? Feel free to reach out.`)}
                </Para>
                <Large style={{margin: 'auto'}}>
                  <a style={{textAlign: 'center'}} href="mailto:team@jaypeggers.com">
                    <Chat>{t(`Let's Chat`)}</Chat>
                  </a>
                </Large>

                <Large style={{margin: 'auto'}} to="/" onClick={() => window.open("https://www.disclaimer-generator.com/live.php?token=aFYcdsVdGGChFq0enuC0VDf45ksP4Oea")}>
                  {t("Disclaimer")}
                </Large>
                <Large style={{margin: 'auto'}} to="/" onClick={() => window.open("https://www.privacypolicygenerator.org/live.php?token=I6s9Uz01sYBE9e0m0FtTw1qqSgfo4Ub5")}>
                  {t("Privacy Policy")} 
                </Large>
                <Large style={{margin: 'auto'}} to="/" onClick={() => window.open("https://www.termsconditionsgenerator.com/live.php?token=IT2y1yDd7zQRXFAx1qF3VLgQqiUAegTf")}>
                  {t("Terms & Conditions")}
                </Large>
              </div>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24} style={{maxWidth: '95vw'}}>
              <a
                style={{ margin: '0 auto', width: '100%', display: 'block'}}
                href="https://github.com/Credshields/Audit-Reports/blob/main/JayPeggers%20Final%20Audit%20Report.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: '100%' }}
                  alt="Credshields Audit Report"
                  src="/img/CSAudited.png"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "2rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <img
                  src="/img/logo.png"
                  aria-label="homepage"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://twitter.com/Jaypeggerz"
                src="twitter.svg"
              />
              <SocialLink
                href="https://discord.gg/DtAzjE4PaJ"
                src="discord.svg"
              />
              <SocialLink
                href="https://medium.com/@Jaypeggers/jaypeggers-integrates-chainlink-price-feeds-to-help-calculate-usd-denominated-platform-fees-2ade22838c90"
                src="medium.svg"
              />
              <SocialLink
                href="https://etherscan.io/address/0xDA7C0810cE6F8329786160bb3d1734cf6661CA6E"
                src="ESbw.svg"
              />

            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
